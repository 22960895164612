import Image1 from './images/full/tropical_charm.png'
import Image2 from './images/full/tropicana_swirl.png'
import Image3 from './images/full/casablanca.png'
import Image4 from './images/full/moroccan_gold.png'
import Image5 from './images/full/persian_attraction.png'
import Image6 from './images/full/bali.png'
import Image7 from './images/full/bali_2.png'
import Image8 from './images/full/java.png'
import Image9 from './images/full/spanish_sahara.png'
import Image10 from './images/full/on_a_journey.png'
import Image11 from './images/full/extravaganza.png'
import Image12 from './images/full/garden_path.png'
import Image13 from './images/full/silent_trio.png'
import Image14 from './images/full/meaningful_silence.png'
import Image15 from './images/full/arise_and_shine.png'
import Image16 from './images/full/blueray_2.png'
import Image17 from './images/full/blueray_1.png'

export default {
  collection: [
    {
      index: 0,
      name: 'Tropical Charm',
      source: Image1,
    },
    {
      index: 1,
      name: 'Tropical Swirl',
      source: Image2,
    },
    {
      index: 2,
      name: 'Casablanca',
      source: Image3,
    },
    {
      index: 3,
      name: 'Moroccan Gold',
      source: Image4,
    },
    {
      index: 4,
      name: 'Persian Attraction',
      source: Image5,
    },
    {
      index: 5,
      name: 'Bali',
      source: Image6,
    },
    {
      index: 6,
      name: 'Bali 2',
      source: Image7,
    },
    {
      index: 7,
      name: 'Java',
      source: Image8,
    },
    {
      index: 8,
      name: 'Spanish Sahara',
      source: Image9,
    },
    {
      index: 9,
      name: 'On A Journey',
      source: Image10,
    },
    {
      index: 10,
      name: 'Extravaganza',
      source: Image11,
    },
    {
      index: 11,
      name: 'Garden Path',
      source: Image12,
    },
    {
      index: 12,
      name: 'Silent Trio',
      source: Image13,
    },
    {
      index: 13,
      name: 'Meaningful Silence',
      source: Image14,
    },
    {
      index: 14,
      name: 'Arise and Shine',
      source: Image15,
    },
    {
      index: 15,
      name: 'Blueray 2',
      source: Image16,
    },
    {
      index: 16,
      name: 'Blueray',
      source: Image17,
    }
  ],
};
