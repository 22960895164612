import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import * as styles from './styles.module.scss';
import images from './images';

const AbstractObsession = (props) => {
  const { collection } = images
  const initialState = {
    photoIndex: 0,
    isOpen: false,
  };

  const [values, setValues]= useState(initialState);
  const setLightBoxIndex = (index) => {
    setValues({
      ...values,
      selectedIndex: index,
      photoIndex: index,
      isOpen: true,
    });
  };

  return (
    <div className={styles.bohoLuxe}>
      <div className={styles.headerContainer}>
        <h1>Boho Luxe</h1>
        <p>
          This collection works best if you want to take your Bohemian interior to a new level
          and give it a luxurious touch or spice up an already glamorous décor. The visual impact
          of each piece is achieved by using earthy materials that carry radiant energy.
        </p>
      </div>
      <div className={styles.bohoLuxeGallery}>
        <div>
          <div className={styles.bohoLuxeRow}>
            <div><img alt="gallery image" src={collection[0].source} onClick={() => setLightBoxIndex(0)}/></div>
            <div><img alt="gallery image" src={collection[1].source} onClick={() => setLightBoxIndex(1)}/></div>
            <div><img alt="gallery image" src={collection[2].source} onClick={() => setLightBoxIndex(2)}/></div>
          </div>
          <div className={styles.bohoLuxeRow}>
            <div><img alt="gallery image" src={collection[3].source} onClick={() => setLightBoxIndex(3)}/></div>
            <div><img alt="gallery image" src={collection[4].source} onClick={() => setLightBoxIndex(4)}/></div>
            <div><img alt="gallery image" src={collection[5].source} onClick={() => setLightBoxIndex(5)}/></div>
          </div>
          <div className={styles.bohoLuxeRow}>
            <div><img alt="gallery image" src={collection[6].source} onClick={() => setLightBoxIndex(6)}/></div>
            <div><img alt="gallery image" src={collection[7].source} onClick={() => setLightBoxIndex(7)}/></div>
          </div>
          <div className={styles.bohoLuxeRow}>
            <div><img alt="gallery image" src={collection[8].source} onClick={() => setLightBoxIndex(8)}/></div>
            <div><img alt="gallery image" src={collection[9].source} onClick={() => setLightBoxIndex(9)}/></div>
            <div><img alt="gallery image" src={collection[10].source} onClick={() => setLightBoxIndex(10)}/></div>
          </div>
          <div className={styles.bohoLuxeRow}>
            <div><img alt="gallery image" src={collection[11].source} onClick={() => setLightBoxIndex(11)}/></div>
            <div><img alt="gallery image" src={collection[12].source} onClick={() => setLightBoxIndex(12)}/></div>
            <div><img alt="gallery image" src={collection[13].source} onClick={() => setLightBoxIndex(13)}/></div>
          </div>
          <div className={styles.bohoLuxeRow}>
            <div><img alt="gallery image" src={collection[14].source} onClick={() => setLightBoxIndex(14)}/></div>
            <div><img alt="gallery image" src={collection[15].source} onClick={() => setLightBoxIndex(15)}/></div>
            <div><img alt="gallery image" src={collection[16].source} onClick={() => setLightBoxIndex(16)}/></div>
          </div>
        </div>
      </div>
      <div className={styles.bohoLuxeGallery}>
        { values.isOpen && (
          <Lightbox
            imageTitle={collection[values.photoIndex].name}
            mainSrc={collection[values.photoIndex].source}
            nextSrc={collection[(values.photoIndex + 1) % collection.length].source}
            prevSrc={collection[(values.photoIndex + collection.length - 1) % collection.length].source}
            onCloseRequest={() => setValues({...values, isOpen: false})}
            onMovePrevRequest={() =>
              setValues({
                ...values,
                photoIndex: (values.photoIndex + collection.length - 1) % collection.length,
              })
            }
            onMoveNextRequest={() =>
              setValues({
                ...values,
                photoIndex: (values.photoIndex + 1) % collection.length,
              })
            }
          />
        )}
      </div>
    </div>
  );
}

export default AbstractObsession;
