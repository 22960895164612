import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BohoLuxe from "../../components/ArtStyle/BoxoLuxe"

const BohoLuxePage = () => (
  <Layout>
    <Seo title="Boxo Luxe" />
    <BohoLuxe />
  </Layout>
)

export default BohoLuxePage
